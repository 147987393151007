import React, { useEffect, useState } from 'react';
import '../Invite.css'; // Assuming you have a CSS file for styles
import { BASE_URL } from '../Shared/Config';

const InviteStep2 = ({ InviteData, onChangeStep, namesList, YourChild }) => {
    const [selectedOptions, setSelectedOptions] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const handleSave = async (jsonData) => {
        const studentData = JSON.parse(JSON.stringify(jsonData));
        const hasStatus1 = jsonData.some(student => student.StudentStatus === "1");

        try {
            let url = `${BASE_URL}sparker8-update-student-initial-invite`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify(jsonData)
            });

            const data = await response;

            if (hasStatus1) {
                onChangeStep(4);
            } else {
                onChangeStep(6);
            }
        } catch (error) {
            console.error('Error saving article:', error);
        }
    };

    const handleNextStep = () => {
        const allSelected = InviteData.every((data) => selectedOptions[data.InitialInviteStudentsId]);
        if (allSelected) {
            const result = Object.keys(selectedOptions).map((id) => ({
                StudentId: id,
                StudentStatus: selectedOptions[id],
            }));
            handleSave(result);
        } else {
            setErrorMessage('Please select an option for each child before proceeding.');
        }
    };

    const handlePreviousStep = () => {
        onChangeStep(5);
    };

    const handleOptionChange = (event, id) => {
        setSelectedOptions({
            ...selectedOptions,
            [id]: event.target.value,
        });
        setErrorMessage(''); // Clear the error message when an option is changed
    };

    useEffect(() => {
        console.log(InviteData);
    }, []); 

    return (
        <div>
            <div className="header-image-container">
                <img src="/images/Logo4c_small.png" alt="Avatar" className='invite-logo' />
            </div>
            <div className="standard-text-container">
                <h3>Important Details</h3>
                <ul className="details-list">
                    <li><span className="">We plan to start in the </span><span className="highlight-orange">Fall of 2024.</span> <span className="">While the group projects are going on, we plan on meeting Wednesdays at ?:00 pm in Kings Mountain, NC</span>.</li>
                    <li><span className="">This will be a project-based program</span>.<span className=""> They can try one project; if they like it and excel, they can join the second project, then the third, and so on. We would like to do maybe </span><span className="highlight-orange">2 group projects per year</span>.</li>
                    <li><span className="">Our goal is to keep the total cost</span><span className="highlight-orange"> under $70 per project</span>. This amount would cover all necessary materials for both individual and group projects, excluding a computer. We hope to do 2 projects per year. </li>
                    <li>The money would not need to be paid all up front. There have been a few individuals who have offered to help students in need-so if the money is an issue, please let us know.</li>
                    
                    Students should have access to a computer at home. If this is not the case, we are willing to collaborate with parents to find an affordable solution.
                </ul>

                <div>
                    <p><span className="highlight-orange">{YourChild} is invited to join our skeeball machine project</span>, where we will build a full-sized, mobile skeeball-style machine (we use "skeeball-style" to respect the trademarked term Skeeball) The individual projects assigned to your child will focus on developing the skills needed to contribute to this group project.</p>
                    <p><span className="highlight-orange">The total costs to participate in this project is $65</span>, and we expect it will take about 4 months to complete.</p>
                    <h3>What is Included:</h3>
                    <ul>
                        <li>Group meetings at least once a month</li>
                        <li>A take-home kit with:
                            <ul>
                                <li>Arduino Mini (a circuit board/computer they'll learn to program)</li>
                                <li>Breadboard & needed hookup wires</li>
                                <li>Custom breadboard holding station</li>
                                <li>Key electrical components such as resistors and transistors</li>
                                <li>Select electrical inputs (sensors) and outputs (LEDs, speaker etc)</li>
                            </ul>
                        </li>
                        <li>Access to Sparkers Club’s online tutorial app, featuring customized tutorials for the kit materials</li>
                        <li>Reward materials (think trophy...but better)</li>
                        <li>Sparkers Club shirt</li>
                    </ul>
                    <p>Additional costs covered by the fees:</p>
                    <ul>
                        <li>Meeting location</li>
                        <li>Cloud hosting services (which can be quite costly)</li>
                        <li>A five million dollar salary to the hosts</li>
                    </ul>
                </div>

                <h3 className="intro-text">Is this something {YourChild.toLowerCase()} may be interested in?</h3>
                <table className="interest-table">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Interest Level</th>
                        </tr>
                    </thead>
                    <tbody>
                        {InviteData.map((data) => (
                            <tr key={data.InitialInviteStudentsId}>
                                <td>{data.FirstName}</td>
                                <td>
                                    <select
                                        value={selectedOptions[data.InitialInviteStudentsId] || ''}
                                        onChange={(event) => handleOptionChange(event, data.InitialInviteStudentsId)}
                                    >
                                        <option value="" disabled>Select an option</option>
                                        <option value="1">Yes, probably interested</option>
                                        <option value="2">Yes, interested but cannot</option>
                                        <option value="3">Not interested</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="button-container">
                    <button className="step-button" onClick={handlePreviousStep}>Previous</button>
                    <button className="step-button" onClick={handleNextStep}>Next</button>
                </div>
            </div>
        </div>
    );
}

export default InviteStep2;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';

import Invite from './Invite';
import Home from './Home';

import './App.css';



function App() {
  const location = useLocation();
  const [showHome, setShowHome] = useState(true);
  const [inviteCode, setInviteCode] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const invite = queryParams.get('invite');
    if (invite !== null && invite !== '') {  
      setInviteCode(invite);
      setShowHome(false);
    }
    else {
      setShowHome(true);
    }
  }, [location]);

  return (
    <div className="app">
      {showHome && <div>
        <div>
          <Home />
        </div>
      </div>}
      {!showHome && <div>
        <Invite inviteCode={inviteCode} />
      </div>}
    </div>
  );
}

export default App;

import React from 'react';
import '../Invite.css'; // Assuming you have a CSS file for styles

const InviteStep4 = ({ FirstName, onChangeStep }) => {
 
    return (
        <div>
                        <div className="header-image-container">
                <img src="/images/Logo4c_small.png" alt="Avatar" className='invite-logo' />
            </div>
        <div className="standard-text-container">
           <h3> Great! We will be in touch!</h3>
            
        </div>
        </div>
    );
}

export default InviteStep4;

const is_local = false;

//let url = 'https://sparker8.azurewebsites.net/';
let url = 'https://sparkerfunctions.azurewebsites.net/'


if (is_local){
    url = 'http://localhost:7071/';
}


export const BASE_URL = url;
import React from 'react';
import '../Invite.css'; // Assuming you have a CSS file for styles

const InviteStep5 = ({ InviteData, onChangeStep, namesList, isOrAre, YourChild }) => {
    const handleNextStep = () => {
        onChangeStep(2);
    };

    const handlePreviousStep = () => {
        onChangeStep(1);
    };
    return (
        <div>
            <div className="header-image-container">
                <img src="/images/Logo4c_small.png" alt="Avatar" className='invite-logo' />
            </div>
            
        <div className="standard-text-container">
            
             <h3>Wonderful! We are excited you would like to learn more.</h3>
            
       <p>We are calling it the <span className="highlight-orange">Sparkers Club</span>, where curiosity ignites invention.</p>
       
       
           <p>Please keep in mind that <span className="highlight-orange">this is a pilot program</span>, and there will be some trial and error. Our overall goal is to create an exceptional program tailored to inspire and educate young innovators.</p>
  
           
           <h3> So what exactly will {namesList} do if they join?</h3>
          
    
          <p>They will use our in-house web-app to follow instructions to complete <span className="highlight-orange">individual projects</span> that will be <span className="highlight-orange">focused on learning electronics, circuits, coding, mechanics, etc.</span>—the core components of making cool stuff. These individual projects will equip them with necessary skills for contributing to the Sparkers Club's <span className="highlight-orange">group projects</span>!</p>
          
          
          <p>The Sparkers Club will <span className="highlight-orange">hold regular meetings (location and time TBD)</span> to discuss individual projects, collaborate on group projects, and reinforce key ideas and concepts.</p>
          <p> Most importantly, <span className="highlight-orange">they will be having fun doing it</span>!</p>
          

          <div className="button-container">
                <button className="step-button" onClick={handlePreviousStep}>Go Back</button>
                <button className="step-button" onClick={handleNextStep}>Go on...</button>
            </div>
        </div>
        </div>
    );
}

export default InviteStep5;

import React from 'react';
import '../Invite.css'; // Assuming you have a CSS file for styles

const InviteStep6 = ({ FirstName, onChangeStep }) => {
    
    return (
        <div>
                        <div className="header-image-container">
                <img src="/images/Logo4c_small.png" alt="Avatar" className='invite-logo' />
            </div>
        <div className="standard-text-container">
           <h3>Understood. It is not for everyone.</h3>
           <p>If they change their mind, just reach out!</p>
            
        </div>
        </div>
    );
}

export default InviteStep6;

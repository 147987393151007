import React from 'react';
import '../Invite.css'; // Assuming you have a CSS file for styles

const InviteStep1 = ({ InviteData, onChangeStep, namesList, isOrAre, YourChild }) => {
    const handleNextStep = () => {
        onChangeStep(5);
    };

   


    return (
        <div className="standard-text-container">
            <h3>Hey!</h3>
            <p>We have started a not-for-profit organization dedicated to <span className="highlight-orange">inspiring young innovators through project-based learning</span>, fostering creativity, sustainability, and a passion for making things.</p>
            
            <p>We are hand-selecting a group of kids we think will excel in our pilot program, <span className="highlight-orange">and {namesList} {isOrAre} the perfect fit.</span> </p>
            
            <p>This is an official invite for them to participate in the pilot program. </p>
           

            <button className="step-button" onClick={handleNextStep}>Tell me more!</button>
        </div>
    );
}

export default InviteStep1;

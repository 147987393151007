import React, { useState, useEffect } from 'react';
import '../Invite.css'; // Assuming you have a CSS file for styles
import { BASE_URL } from '../Shared/Config';

const InviteStep3 = ({ onChangeStep }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [preferredContact, setPreferredContact] = useState('phone');
    const [comments, setComments] = useState('');
    const [initialInviteStudentsIds, setInitialInviteStudentsIds] = useState([]);



    useEffect(() => {
        fetchInitialInviteStudents();
    }, []);

    const fetchInitialInviteStudents = async () => {
        try {
            let url = `${BASE_URL}sparker8-update-student-initial-invite`;
            const response = await fetch(BASE_URL + 'sparker8-get-initial-invite-students?invite_code=frye');
            const data = await response.json();
            const ids = data.map(student => student.InitialInviteStudentsId);

            
            setInitialInviteStudentsIds(ids);
        } catch (error) {
            console.error('Error fetching initial invite students:', error);
        }
    };


    const handleNextStep = async () => {
        let data = {
            "Name": firstName,
            "Email": email,
            "Phone": phone,
            "Method": preferredContact === "phone" ? 1 : 2,
            "Comments": comments,
            "Students": initialInviteStudentsIds
        }
    
        try {
            let url = `${BASE_URL}sparker8-update-student-initial-invite-guardian`;
            
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify(data)
            });
    
          
                const returnData = await response;
                
                onChangeStep(6);
          
        }
        catch(error) {
            console.error('Error saving data:', error);
        }
    };

    const handlePreviousStep = () => {
        onChangeStep(5);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handlePreferredContactChange = (e) => {
        setPreferredContact(e.target.value);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };

    return (
        <div className="invite-step">
            <p className="intro-text">Awesome! It looks like you have a smart kid interested in MAKING stuff!</p>
            <p className="highlight-text">We will keep you updated on our progress.</p>

            <div className="contact-form">
                <div className="form-group">
                    <label htmlFor="firstName">Name:</label>
                    <input
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        placeholder="Enter your name"
                        className="input-field"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Enter your email"
                        className="input-field"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Phone:</label>
                    <input
                        type="tel"
                        id="phone"
                        value={phone}
                        onChange={handlePhoneChange}
                        placeholder="Enter your phone number"
                        className="input-field"
                        pattern="[0-9]*"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="preferredContact">Preferred Method of Communication:</label>
                    <select
                        id="preferredContact"
                        value={preferredContact}
                        onChange={handlePreferredContactChange}
                        className="input-field"
                    >
                        <option value="email">Email</option>
                        <option value="phone">Text Message</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="comments">Any Comments:</label>
                    <textarea
                        id="comments"
                        value={comments}
                        onChange={handleCommentsChange}
                        placeholder="Enter your comments here"
                        className="input-field"
                    />
                </div>
            </div>

            <div className="button-container">
                <button className="step-button" onClick={handlePreviousStep}>Previous</button>
                <button className="step-button" onClick={handleNextStep}>Next</button>
            </div>
        </div>
    );
};

export default InviteStep3;

import React, { useEffect, useState } from 'react';
import './Invite.css'; // Import your home page styles
import { Link } from 'react-router-dom';
import InviteStep1 from './InviteFlow/InviteStep1';
import InviteStep2 from './InviteFlow/InviteStep2';
import InviteStep3 from './InviteFlow/InviteStep3';
import InviteStep4 from './InviteFlow/InviteStep4';
import InviteStep5 from './InviteFlow/InviteStep5';
import InviteStep6 from './InviteFlow/InviteStep6';
import { BASE_URL } from './Shared/Config';

const Invite = ({inviteCode}) => {
    const [showStep1, setShowStep1] = useState(true);
    const [showStep2, setShowStep2] = useState(false);
    const [showStep3, setShowStep3] = useState(false);
    const [showStep4, setShowStep4] = useState(false);
    const [showStep5, setShowStep5] = useState(false);
    const [showStep6, setShowStep6] = useState(false);
    const [isOrAre, setisOrAre]= useState("");
    const [YourChild, setYourChild] = useState("");
    const [inviteData, setInviteData] = useState(null);
    const [namesList, setNamesList] = useState(null);
    const [inviteNotFound, setInviteNotFound] = useState(false);

    const formatNamesList = (data) => {
        const names = data.map((item) => item.FirstName);
        if (names.length === 1) {
            return names[0];
        } else if (names.length === 2) {
            return names.join(' and ');
        } else {
            return names.slice(0, -1).join(', ') + ', and ' + names[names.length - 1];
        }
    };

    //const namesList = formatNamesList(InviteData);

    useEffect(() => {
        fetch(`${BASE_URL}sparker8-get-initial-invite-students?invite_code=${inviteCode}`)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    // If invite is found, set invite data
                    setInviteData(data); // Assuming only one invite is returned
                    setNamesList(formatNamesList(data));
                    const _isOrAre = data.length === 1 ? 'is' : 'are';
                    const _YourChild = data.length === 1 ? 'Your child' : 'Your children';

                    setisOrAre(_isOrAre);
                    setYourChild(_YourChild);
                    setInviteNotFound(false);
                } else {
                    // If invite is not found, display message
                    setInviteNotFound(true);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setInviteNotFound(true); // Handle error case
            });
    }, []); // Empty dependency array ensures this runs once on component mount

    const onChangeStep = (step) => {
        console.log(step);
        if (step === 2) {
            setShowStep2(true);
            setShowStep1(false);
            setShowStep3(false);
            setShowStep4(false);
            setShowStep5(false);
            setShowStep6(false);
        }
        else if (step === 1){
            setShowStep2(false);
            setShowStep1(true);
            setShowStep3(false);
            setShowStep4(false);
            setShowStep5(false);
            setShowStep6(false);
        }
        else if (step === 3){
        
            setShowStep2(false);
            setShowStep1(false);
            setShowStep3(true);
            setShowStep4(false);
            setShowStep5(false);
            setShowStep6(false);
        }
        else if (step === 4){
        
            setShowStep2(false);
            setShowStep1(false);
            setShowStep3(false);
            setShowStep4(true);
            setShowStep5(false);
            setShowStep6(false);
        }
        else if (step === 5){
        
            setShowStep2(false);
            setShowStep1(false);
            setShowStep3(false);
            setShowStep4(false);
            setShowStep5(true);
            setShowStep6(false);
        }
        else if (step === 6){
        
            setShowStep2(false);
            setShowStep1(false);
            setShowStep3(false);
            setShowStep4(false);
            setShowStep5(false);
            setShowStep6(true);
        }
    };

    return (
        <div className="main-container">
            <br />
            <div>
                {inviteData && showStep1 && (
                    <div>
                        <InviteStep1 InviteData={inviteData} namesList={namesList} isOrAre={isOrAre} YourChild={YourChild} onChangeStep={onChangeStep} />
                    </div>
                )}
                {inviteData && showStep2 && (
                    <div>
                        <InviteStep2 InviteData={inviteData} namesList={namesList}  isOrAre={isOrAre} YourChild={YourChild} onChangeStep={onChangeStep} />
                    </div>
                )}
                {inviteData && showStep3 && (
                    <div>
                        <InviteStep3 FirstName={inviteData.FirstName} namesList={namesList}  isOrAre={isOrAre} YourChild={YourChild} onChangeStep={onChangeStep} />
                    </div>
                )}
                {inviteData && showStep4 && (
                    <div>
                        <InviteStep4 FirstName={inviteData.FirstName} namesList={namesList}  isOrAre={isOrAre} YourChild={YourChild} onChangeStep={onChangeStep} />
                    </div>
                )}
                {inviteData && showStep5 && (
                    <div>
                        <InviteStep5 FirstName={inviteData.FirstName} namesList={namesList}  isOrAre={isOrAre} YourChild={YourChild} onChangeStep={onChangeStep} />
                    </div>
                )}
                {inviteData && showStep6 && (
                    <div>
                        <InviteStep6 FirstName={inviteData.FirstName} namesList={namesList}  isOrAre={isOrAre} YourChild={YourChild} onChangeStep={onChangeStep} />
                    </div>
                )}
                {inviteNotFound && <div>Invite not found or error loading invite-{inviteCode}</div>}
            </div>
        </div>
    );
};

export default Invite;
